<template>
  <div class="left-section">
    <div class="d-flex flex-column pt-5" style="width: 320px;">
      <volunteer-info-card :profile="profile" />
      <list-info-card
        :icon="require('@/assets/svg/world.svg')"
        :title="$t('follow_agencies')"
        :text="`${sideSection.agency} Agencies`"
        :onClicked="goToAgencies"
      />
      <list-info-card
        :icon="require('@/assets/svg/volunteers.svg')"
        :title="$t('volunteers')"
        :text="sideSection.volunteer + ' volunteers'"
        :onClicked="goToVolunteers"
      />
      <list-info-card
        :icon="require('@/assets/svg/store.svg')"
        title="Store"
        text="180 Products"
        :onClicked="goToStore"
        class="mb-5 d-none"
      />
      <ads-viewer class="mt-5" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import ListInfoCard from "../../components/common/ListInfoCard.vue";
import VolunteerInfoCard from "./VolunteerInfoCard.vue";
import AdsViewer from "../../components/ads/AdsViewer.vue";
export default {
  components: { ListInfoCard, VolunteerInfoCard, AdsViewer },
  computed: {
    ...mapState("auth", {
      profile: "profile",
      sideSection: "sideSection",
    }),
  },
  methods: {
    ...mapActions("auth", {
      getLeftSectionInfo: "getLeftSectionInfo",
    }),
    goToVolunteers() {
      if (this.$route.name != "volunteers") {
        this.$router.push({ name: "volunteers" });
      }
    },
    goToStore() {
      if (this.$route.name != "agency-shop") {
        this.$router.push({ name: "agency-shop" });
      }
    },
    goToAgencies() {
      if (this.$route.name != "volunteer-agencies") {
        this.$router.push({ name: "volunteer-agencies" });
      }
    },
  },
  mounted() {
    this.loading = true;
    this.getLeftSectionInfo()
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  },
};
</script>
<style scoped>
.left-section {
  max-height: 0;
  overflow-y: hidden;
  position: sticky;
  min-height: inherit;
  overflow-x: hidden;
  top: 70px;
  max-width: 430px;
  min-width: 430px;
  width: 430px;
  padding-left: 100px;
}
.left-section:hover {
  overflow-y: auto;
}
.left-section::-webkit-scrollbar {
  width: 8px;
  transition-duration: 0.5s;
}

/* Track */
.left-section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.left-section::-webkit-scrollbar-thumb {
  background: rgba(240, 160, 0, 0.3);
  border-radius: 3px;
}

/* Handle on hover */
.left-section::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
