<template>
  <div class="right-section ">
    <div class="d-flex flex-column" style="width: 320px">
      <donation-button />
      <div class="mt-5 d-flex flex-row">
        <div
          class="d-flex flex-column justify-center align-center mr-9 circle-button"
          @click="goToCalendar"
        >
          <div class="inside-circle">
            <v-img
              :src="require('@/assets/svg/calendar.svg')"
              width="28"
              height="28"
              contain
            />
          </div>
          <span class="mt-3">{{ $t("needs") }}</span>
          <span>{{ $t("calender") }}</span>
        </div>
        <div
          class="d-flex flex-column justify-center align-center mr-9 circle-button"
          @click="goToChat"
        >
          <div class="inside-circle">
            <v-img
              :src="require('@/assets/svg/chat.svg')"
              width="30"
              height="30"
              contain
            />
          </div>
          <span class="mt-3">{{ $t("volunteer") }}</span>
          <span>Chat</span>
        </div>
        <div
          class="d-flex flex-column justify-center align-center circle-button"
          @click="goToAwards"
        >
          <div class="inside-circle">
            <v-img
              :src="require('@/assets/svg/awards.svg')"
              width="30"
              height="30"
              contain
            />
          </div>
          <span class="mt-3">{{ $t("volunteer") }}</span>
          <span>{{ $t("awards") }}</span>
        </div>
      </div>
      <list-info-card
        :icon="require('@/assets/svg/blog.svg')"
        title="Blog"
        :text="sideSection.blog + ' articles'"
        :onClicked="goToBlogs"
      />
      <list-info-card
        :icon="require('@/assets/svg/needs-blue.svg')"
        :title="$t('needs')"
        :text="sideSection.need + ' Needs'"
        :onClicked="goToNeeds"
        v-if="$route.name != 'agency-needs'"
      />
      <list-info-card
        :icon="require('@/assets/svg/contacts.svg')"
        :title="$t('contact')"
        :text="conversations.length + ' Contacts'"
        :onClicked="goToChat"
      />
      <list-info-card
        :icon="weatherIcon"
        :title="$t('weather')"
        :text="weatherText"
        :onClicked="goToWeather"
      />
      <list-info-card
        :icon="require('@/assets/svg/map.svg')"
        :title="$t('map')"
        :text="sideSection.location + ' Novelties'"
        :onClicked="goToMap"
        class="mb-5"
      />
    </div>
  </div>
</template>
<script>
import DonationButton from "@/components/common/DonationButton.vue";
import ListInfoCard from "@/components/common/ListInfoCard.vue";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  components: { ListInfoCard, DonationButton },
  methods: {
    ...mapActions({
      getRightSectionInfo: "auth/getRightSectionInfo",
      getCityWeather: "weather/getCityWeather",
      setWeather: "auth/setWeather",
    }),
    onClicked() {
      console.log("clicked");
    },
    goToNeeds() {
      if (this.$route.name != "agency-need-feeds") {
        this.$router.push({ name: "agency-need-feeds" });
      }
    },
    goToChat() {
      this.$router.push({ name: "chat" });
    },
    goToBlogs() {
      if (this.$route.name != "blog-feeds") {
        this.$router.push({ name: "blog-feeds" });
      }
    },
    goToCalendar() {
      if (this.$route.name != "calendar") {
        this.$router.push({ name: "calendar" });
      }
    },
    goToAwards() {
      if (this.$route.name != "awards") {
        this.$router.push({ name: "awards" });
      }
    },
    goToMap() {
      if (this.$route.name != "need-map") {
        this.$router.push({ name: "need-map" });
      }
    },
    goToWeather() {
      if (this.$route.name != "weather") {
        this.$router.push({ name: "weather" });
      }
    },
  },
  computed: {
    ...mapState("auth", {
      sideSection: "sideSection",
      weather: "weather",
      profile: "profile",
    }),
    ...mapGetters({
      conversations: "chat/getConversations",
      unit: "usersetting/getUnit",
    }),
    weatherText() {
      if (!this.weather) return "";
      return `${this.weather.weather[0]["main"]}  ${this.weather.main.temp}°${
        this.unit == "imperial" ? "F" : "C"
      }`;
    },
    weatherIcon() {
      if (!this.weather) return require("@/assets/svg/weather.svg");
      return require(`@/assets/svg/weathers/${this.weather.weather[0]["icon"]}.svg`);
    },
  },
  watch: {
    profile(newValue) {
      if (newValue.zip && !this.weather) {
        this.getCityWeather({
          unit: this.unit,
          zip: newValue.zip,
          state: newValue.state,
          city: newValue.city,
        })
          .then((res) => {
            this.setWeather(res);
          })
          .catch((error) => console.log(error));
      }
    },
  },
  mounted() {
    this.loading = true;
    this.getRightSectionInfo()
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
    if (this.weather == null && this.profile.zip) {
      this.getCityWeather({
        unit: this.unit,
        zip: this.profile.zip,
        state: this.profile.state,
        city: this.profile.city,
      })
        .then((res) => {
          this.setWeather(res);
        })
        .catch((error) => console.log(error));
    }
  },
};
</script>
<style scoped>
.right-section {
  max-height: 0;
  overflow-y: hidden;
  position: sticky;
  min-height: inherit;
  overflow-x: hidden;
  top: 70px;
  min-width: 400px;
  max-width: 400px;
}
.right-section:hover {
  overflow-y: auto;
}
.right-section::-webkit-scrollbar {
  width: 8px;
  transition-duration: 0.5s;
}

/* Track */
.right-section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.right-section::-webkit-scrollbar-thumb {
  background: rgba(240, 160, 0, 0.3);
  border-radius: 3px;
}

/* Handle on hover */
.right-section::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}

.circle-button {
  font-size: 12px;
  font-family: "Poppins-Bold";
  cursor: pointer;
}
.circle-button:hover .inside-circle {
  background: #f6f6f6;
}
.inside-circle {
  background: white;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
}
</style>
