<template>
  <v-hover v-slot="{ hover }" v-if="ads._id">
    <v-card
      class="mb-4"
      width="100%"
      v-observe-visibility="{
        callback: visibilityChanged,
        intersection: {
          threshold: 0.8,
        },
        throttle: 600,
        throttleOptions: {
          leading: 'visible',
        },
      }"
    >
      <v-card-title style="font-size: 12px">
        <editable-avatar
          :image="ads.business_image"
          :editable="false"
          :size="40"
          class="mr-5"
        />
        {{ ads.business_name }}
        <span style="font-weight: 200">'s advertisement</span>
      </v-card-title>
      <v-img
        :src="ads.file"
        alt="ads"
        cover
        v-if="ads.type == 'image'"
        @click="goToWebsite"
      />
      <div v-else-if="ads.type == 'video' && ads.file">
        <div class="position-relative d-flex justify-center align-center">
          <vue-plyr
            ref="plyr"
            :options="{
              autoplay: false,
              muted: true,
              autopause: true,
              controls: [
                'play',
                'progress',
                'current-time',
                'mute',
                'volume',
                'fullscreen',
              ],
            }"
          >
            <video
              controls
              crossorigin
              playsinline
              id="videoElement"
              @ended="onEnded"
            >
              <source :src="ads.file" type="video/mp4" />
            </video>
          </vue-plyr>
          <div class="absolute-center">
            <v-btn v-if="hover" fab color="primary" @click="goToWebsite"
              ><v-icon>mdi-link</v-icon></v-btn
            >
          </div>
        </div>
      </div>
      <v-card-text class="text-block" @click="goToWebsite">
        {{ ads.text }}
      </v-card-text>
    </v-card>
  </v-hover>
</template>
<script>
import { mapActions } from "vuex";
import EditableAvatar from "../EditableAvatar.vue";
export default {
  components: { EditableAvatar },
  data() {
    return {
      ads: {},
    };
  },
  methods: {
    ...mapActions("ads", {
      getAds: "getAds",
    }),
    goToWebsite() {
      window.open(this.ads.url);
    },
    onEnded() {
      this.$refs.plyr.player.play();
    },
    visibilityChanged(isVisible, entry) {
      if (isVisible && entry.intersectionRatio > 0.5) {
        if (this.ads.type == "video") {
          this.$refs.plyr.player.muted = true;
          this.$refs.plyr.player.play();
        }
      } else if (this.$refs.plyr) this.$refs.plyr.player.muted = true;
    },
  },
  mounted() {
    this.getAds()
      .then((res) => {
        this.ads = res;
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  },
};
</script>
